@import url('https://fonts.googleapis.com/css2?family=Newsreader:ital,opsz,wght@0,6..72,200..800;1,6..72,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.fontNewsreader{
    font-family: "Newsreader", serif !important;
    font-optical-sizing: auto;
  }
  .mulish{
    font-family: "Mulish", sans-serif !important;
    font-optical-sizing: auto;
  }
  
  .kanit {
    font-family: "Kanit", sans-serif !important;
    font-optical-sizing: auto;
  }


  .cta {
    position: relative;
    padding: 8rem 0;
    background-image: url("https://source.unsplash.com/5g41N3uRPf8/1500x800");
    background-position: center;
    background-size: cover;
    overflow: hidden;
    z-index: 99;
}

.cta:before {
    /* padding: 10rem 0; */
    background-image: url(../../assets/img/cta-bg.jpg) !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform 0.5s ease;
    z-index: -1;
}

.cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.cta:hover:before {
    transform: scale(1.1);
}

.querytitle{
    font-size: calc(1.425rem + 2.1vw) !important;
    font-weight: 600;
}
.querytitle a{
 color: #3695B6;
}