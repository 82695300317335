/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* For WebKit-based browsers like Chrome and Safari */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #bb4a9b;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #dd5500;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

/* For Firefox */
body {
  /* scrollbar-color: #bb4a9b #f2f2f2; */
}

/* For Edge (Chromium-based) */
::-ms-scrollbar-thumb {
  background-color: #bb4a9b;
  border-radius: 6px;
}

::-ms-scrollbar-thumb:hover {
  background-color: #dd5500;
}

::-ms-scrollbar-track {
  background-color: #f2f2f2;
}





.navHeader::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
.navHeader::-webkit-scrollbar-track {
  background: #f1f1f1 !important; 
}
 
/* Handle */
.navHeader::-webkit-scrollbar-thumb {
  background: #bb4a9b !important; 
}

/* Handle on hover */
.navHeader::-webkit-scrollbar-thumb:hover {
  background: #bb4a9b !important; 
}
/* Add this to your global CSS file */
input::placeholder {
  text-transform: capitalize;
}

.MuiInputBase-input::placeholder {
  text-transform: capitalize;
} 