.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    background-color: #f9f9f9;
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .custom-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .custom-table tr:hover {
    background-color: #ddd;
  }
  