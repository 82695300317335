.selectlogin{
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.text-style{
    font-size: 1em; 
    text-align:left ;
    margin: 10px 0px;
    
}
.h2cl{
    color:#012970;

} 
 
 
.Conimg{
    width: 100%;
    height: 352px;
    object-fit: contain;
}
.inputLogin{
    /* color: red; */
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    /* border: 1px solid #ced4da; */
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.buttonlogin{
    background-color: #0d6efd;
    color: white;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin:14px 0px;
}

.logincontainer{
    box-shadow: 0px 3px 6px #00000029;
    padding: 40px 30px ;
    background:  white ;
    border-radius: 5px;
}
.passContainer{
    position:relative;

    width: 100%;
}
.icon{
    height: 30px;
    position: absolute;
    /* left:0px; */
    width: auto;
    right: 2px;
    /* top:0px; */
    top: 50%;
    transform: translateY(-50%);
}